// prettierrc 100  , editor codestyle javascript hardwrap at 120

/*
note - areas[] on the property record will be the current accepted area set (can change over time)
an area cannot be deleted if it is a part of an active lease. it can change in nla for calculations.
discuss with mohan - what happens if lease says 20% of whole, 60% of building A but with the new calculations using nla, it calculates 21.5% of whole, 65% of building A  . it is possible that another building is added to Broadmeadows or one is knocked down. does the proportion of whole get adjusted even if there are more/less occupants on the whole property.

lease: { cust, code, info, areas: [ { code: all, overrideperc: 20.0 }, {code: BA, overrideperc: 60.0}, {code: BA.AA.1, nla: 155 }]
$1000 breakup based on area
ALL: 200
BA: 600
BA.AA: 155 / nla total for BA * 100
BA.AA.1: 155 / 933 * 100

should all leases create the perc as at the time of creation of the lease and not calculate as you go?

 */

const allAreas = { code: 'ALL', name: 'Whole Site', gla: 50773, nla: 48848, ref: 'F125', childrenCodes: ['BA', 'BB', 'BC', 'BD', 'WA', 'WB', 'WC', 'WD'] };

const mainAreas = [
    { code: 'BA', name: 'Building A', gla: 9118, nla: 8612, childrenCodes: ['BA.AA', 'BA.AB', 'BA.AC'] },
    { code: 'BB', name: 'Building B', gla: 1169, nla: 1109, childrenCodes: ['BB.G', 'BB.1'] },
    { code: 'BC', name: 'Building C', gla: 405, nla: 367, childrenCodes: ['BC.G'] },
    { code: 'BD', name: 'Building D', gla: 507, nla: 470, childrenCodes: ['BD.G'] },
    { code: 'WA', name: 'Warehouse A', gla: 25086, nla: 24257, childrenCodes: ['WA.G', 'WA.GS', 'WA.1.A', 'WA.1.B', 'WA.1.C', 'WA.1.D', 'WA.1.S'] },
    { code: 'WB', name: 'Warehouse B', gla: 1561, nla: 1522, childrenCodes: ['WB.ALL'] },
    { code: 'WC', name: 'Warehouse C', gla: 618, nla: 618, childrenCodes: ['WC.A', 'WC.B', 'WC.C', 'WC.S'] },
    { code: 'WD', name: 'Warehouse D', gla: 12309, nla: 11893, childrenCodes: ['WD.WA', 'WD.WB', 'WD.WC', 'WD.WD', 'WD.OA', 'WD.OB', 'WD.OC', 'WD.OD', 'WD.S'] }, //f115
];

const subAreasBLDA = [
    { code: 'BA.AA', name: 'Building A, Tower AA', gla: 5844, nla: 5598, childrenCodes: ['BA.AA.G', 'BA.AA.1', 'BA.AA.2', 'BA.AA.3', 'BA.AA.4', 'BA.AA.5'] },
    { code: 'BA.AB', name: 'Building A, Section AB', gla: 1500, nla: 1500, childrenCodes: ['BA.AB.G', 'BA.AB.1', 'BA.AB.2'] },
    { code: 'BA.AC', name: 'Building A, Section AC', gla: 1774, nla: 1514, childrenCodes: ['BA.AC.G', 'BA.AC.1', 'BA.AC.2'] },
    // { code: 'BA.G', name: 'Building A, Whole Ground Floor', gla: 2275, nla: 2082, childrenCodes: ['BA.AA.G', 'BA.AB.G', 'BA.AC.G'] },
    // { code: 'BA.1', name: 'Building A, Whole First Floor', gla: 2091, nla: 1970, childrenCodes: ['BA.AA.1', 'BA.AB.1', 'BA.AC.1'] },
    // { code: 'BA.2', name: 'Building A, Whole Second Floor', gla: 1830, nla: 1761, childrenCodes: ['BA.AA.2', 'BA.AB.2', 'BA.AC.2'] },
];

const subAreasWHA = [{ code: 'WA.1', name: 'Warehouse A, Level 1', gla: 20000, nla: 20000, childrenCodes: ['WA.1.W', 'WA.1.O'] }];

const areaGroups = [allAreas, ...mainAreas, ...subAreasBLDA, ...subAreasWHA];

const lettableAreas = [
    { code: 'BA.AA.G', name: 'Building A, Tower AA, Ground Floor', gla: 974, nla: 933 },
    { code: 'BA.AA.1', name: 'Building A, Tower AA, First Floor', gla: 974, nla: 933 },
    { code: 'BA.AA.2', name: 'Building A, Tower AA, Second Floor', gla: 974, nla: 933 },
    { code: 'BA.AA.3', name: 'Building A, Tower AA, Third Floor', gla: 974, nla: 933 },
    { code: 'BA.AA.4', name: 'Building A, Tower AA, Forth Floor', gla: 974, nla: 933 },
    { code: 'BA.AA.5', name: 'Building A, Tower AA, Fifth Floor', gla: 974, nla: 933 },
    { code: 'BA.AB.G', name: 'Building A, Bridge AB, Ground Floor', gla: 500, nla: 500 },
    { code: 'BA.AB.1', name: 'Building A, Bridge AB, First Floor', gla: 500, nla: 500 },
    { code: 'BA.AB.2', name: 'Building A, Bridge AB, Second Floor', gla: 500, nla: 500 },
    { code: 'BA.AC.G', name: 'Building A, AC, Ground Floor', gla: 801, nla: 649 },
    { code: 'BA.AC.1', name: 'Building A, AC, First Floor', gla: 617, nla: 537 },
    { code: 'BA.AC.2', name: 'Building A, AC, Second Floor', gla: 356, nla: 328 },
    { code: 'BB.G', name: 'Building B, Ground Floor', gla: 701, nla: 671 }, // 1109 from F116 - 438 from level 1
    { code: 'BB.1', name: 'Building B, First Floor', gla: 468, nla: 438 }, // office + kitchen + amenities
    { code: 'BC.G', name: 'Building C, Ground Floor', gla: 405, nla: 367 }, // office + tea room + amenities + store (406 on F96, 405 on plans
    { code: 'BD.G', name: 'Building D, Ground Floor', gla: 507, nla: 470 }, // office + tea room + amenities
    { code: 'WA.1.A', name: 'Warehouse A, Level 1, Area A', gla: 6354, nla: 6096 },
    { code: 'WA.1.B', name: 'Warehouse A, Level 1, Area B', gla: 1895, nla: 1781 },
    { code: 'WA.1.C', name: 'Warehouse A, Level 1, Area C', gla: 2395, nla: 2363 },
    { code: 'WA.1.D', name: 'Warehouse A, Level 1, Area D', gla: 4703, nla: 4628 },
    { code: 'WA.1.S', name: 'Warehouse A, Store Breezeway', gla: 1834, nla: 1834 },
    { code: 'WA.G', name: 'Warehouse A, Ground Floor', gla: 7762, nla: 7412 },
    { code: 'WA.GS', name: 'Warehouse A, Store Garage', gla: 143, nla: 143 },
    { code: 'WB.ALL', name: 'Warehouse B', gla: 1561, nla: 1522 },
    { code: 'WC.A', name: 'Warehouse C, A', gla: 152, nla: 152 },
    { code: 'WC.B', name: 'Warehouse C, B', gla: 121, nla: 121 },
    { code: 'WC.C', name: 'Warehouse C, C', gla: 299, nla: 299 },
    { code: 'WC.S', name: 'Warehouse C, Store', gla: 46, nla: 46 },
    { code: 'WD.WA', name: 'Warehouse D, Warehouse A', gla: 2175, nla: 2175 },
    { code: 'WD.WB', name: 'Warehouse D, Warehouse B', gla: 1675, nla: 1675 },
    { code: 'WD.WC', name: 'Warehouse D, Warehouse C', gla: 2174, nla: 2174 },
    { code: 'WD.WD', name: 'Warehouse D, Warehouse D', gla: 2273, nla: 2273 },
    { code: 'WD.OA', name: 'Warehouse D, Office A', gla: 598, nla: 392 },
    { code: 'WD.OB', name: 'Warehouse D, Office B', gla: 281, nla: 165 },
    { code: 'WD.OC', name: 'Warehouse D, Office C', gla: 2469, nla: 2469 },
    { code: 'WD.OD', name: 'Warehouse D, Office D', gla: 546, nla: 452 },
    { code: 'WD.S', name: 'Warehouse D, Mezzanine Store', gla: 118, nla: 118 },
];
const billProfileExceptions = [{ billProfileLink: { xxx: false }, leaseLink: { id: 111 }, customPerc: 0 }];

const leases = [
    {
        code: 'TENANT1',
        areas: [
            { code: 'ALL', overridePerc: 0.88 },
            { code: 'BA', overridePerc: 8.4 },
            { code: 'BA.AA.1', floorArea: 100 },
        ],
    },
    { code: 'TENANT2', areas: [{ code: 'BA.AA.G', floorArea: 120 }] },
    { code: 'TENANT3', areas: [{ code: 'BA.AA.G', floorArea: 880 }] },
    { code: 'TENANT4', areas: [{ code: 'BA.AA.1', floorArea: 700 }] },
    { code: 'TENANT5', areas: [{ code: 'BA.AA.1', floorArea: 200 }] },
];

// BA nla = 933 * 6 = 5598
// bill for BA $1000 grossed up to 100%
// 5% tenant 1 = 50  -> this is overridden with 8.4% = 84
// 44% tenant 2 = 440
// 6% tenant 3 = 60
// 35% tenant 4 = 350
// 10% tenant 5 = 100
// therefore total recovered in this case is $1034 out of $1000

const bills = [
    {
        vendor: 'ENERGY',
        billProfile: 'BPAY123',
        amount: 1000.0,
    },
    {
        vendor: 'ENERGY',
        billProfile: 'BPAY124',
        amount: 1000.0,
    },
    {
        vendor: 'ENERGY',
        billProfile: 'BPAY125',
        amount: 1000.0,
    },
];

const billProfiles = [
    {
        vendor: 'ENERGY',
        billProfile: 'BPAY123',
        lines: [
            { item: 'ELECTU', chargeType: 'area', chargeCode: 'BA', chargeMethod: 'nla', percentage: 100.0 }, // nla, nla grossed up to 100%
        ],
    },
    {
        vendor: 'ENERGY',
        billProfile: 'BPAY124',
        lines: [
            { item: 'ELECTU', chargeType: 'area', chargeCode: 'ALL', chargeMethod: 'nla', percentage: 100.0 }, // nla, nla grossed up to 100%
        ],
    },
    {
        vendor: 'ENERGY',
        billProfile: 'BPAY125',
        lines: [
            { item: 'ELECTU', chargeType: 'area', chargeCode: 'BA.AA.1', chargeMethod: 'nla grossed up', percentage: 100.0 }, // nla, nla grossed up to 100%
        ],
    },
];

const areas = [...areaGroups, ...lettableAreas];

const buildExpandedAreas = (areaCode, parentArea) => {
    if (!areaCode) {
        const expandedAreas = areas.flatMap((oneArea) => buildExpandedAreas(oneArea.code, oneArea));

        return expandedAreas;
    }

    const area = areas.find((oneArea) => oneArea.code === areaCode);

    if (!area) return [];

    if (area.childrenCodes) {
        return area.childrenCodes.flatMap((oneChildrenCode) => buildExpandedAreas(oneChildrenCode, parentArea));
    }

    return {
        groupArea: { ...parentArea },
        letArea: { ...area },
        areaOfGroupProportion: area.nla / parentArea.nla,
    };
};

const getExpandedAreas = () => {
    const rawExpandedAreas = buildExpandedAreas();

    const expandedAreas = rawExpandedAreas.map((oneArea) => {
        const totalCalcedNla = rawExpandedAreas.filter((oneArea1) => oneArea1.groupArea.code === oneArea.groupArea.code).reduce((accum, oneArea1) => accum + oneArea1.letArea.nla, 0);
        const totalCalcedGla = rawExpandedAreas.filter((oneArea1) => oneArea1.groupArea.code === oneArea.groupArea.code).reduce((accum, oneArea1) => accum + oneArea1.letArea.gla, 0);

        return {
            ...oneArea,
            searchFields: `
            ${oneArea.groupArea.code} ${oneArea.groupArea.name} ${oneArea.groupArea.nla}
            ${oneArea.letArea.code} ${oneArea.letArea.name} ${oneArea.letArea.nla}
            ${totalCalcedNla}
            ${totalCalcedGla}
            `,
            totalCalcedNla,
            totalCalcedGla,
        };
    });

    return expandedAreas;
};

const getExpandedAreasByLease = () => {
    // const expandedAreas = getExpandedAreas();
    //
    // const expandedAreasByLease =
};

const sampleExpandedAreas = [
    {
        groupArea: {
            code: 'WA',
            name: 'Warehouse A',
            gla: 0,
            nla: 50000,
        },
        letArea: {
            code: 'WA.G',
            name: 'Ground Floor',
            gla: 0,
            nla: 3000,
        },
        areaOfGroupProportion: 3000 / 50000,
        leasedProportion: 0 / 50000,
    },
    {
        groupArea: {
            code: 'WA',
            name: 'Warehouse A',
            gla: 0,
            nla: 50000,
        },
        letArea: {
            code: 'WA.1.WH',
            name: 'Level 1, Warehouse',
            gla: 0,
            nla: 3000,
        },
        areaOfGroupProportion: 3000 / 50000,
        leasedProportion: 0 / 50000,
    },
    {
        groupArea: {
            code: 'WA',
            name: 'Warehouse A',
            gla: 0,
            nla: 50000,
        },
        letArea: {
            code: 'WA.1.OFF',
            name: 'Level 1, Office',
            gla: 0,
            nla: 3000,
        },
        areaOfGroupProportion: 3000 / 50000,
        leasedProportion: 100 / 50000,
    },
];

const sampleExpandedAreasByLease = [
    {
        groupArea: {
            code: 'WA',
            name: 'Warehouse A',
            gla: 0,
            nla: 50000,
        },
        letArea: {
            code: 'WA.1.OFF',
            name: 'Level 1, Office',
            gla: 0,
            nla: 3000,
        },
        lease: {
            code: 'AUSPOST',
            floorArea: 100,
        },
        leasedProportion: 100 / 50000,
    },
];

export default { areas, leases, bills, billProfiles, getExpandedAreas };

<template>
    <div>

        <smart-table v-if="areas" :headers="headers" :items="areas" is-filter :items-per-page="9999" :itemsPerPageOptions="[9999]">
            <template v-slot:item.groupArea.gla="{ item }">
                {{ item.groupArea.gla }}<success-icon :is-success="item.isMatchingGla" /> <fragment v-if="!item.isMatchingGla">(calced {{item.totalCalcedGla}})</fragment>
            </template>
            <template v-slot:item.groupArea.nla="{ item }">
                {{ item.groupArea.nla }}<success-icon :is-success="item.isMatchingNla" /> {{ getNlaPerc(item) }}% <fragment v-if="!item.isMatchingNla">(calced {{item.totalCalcedNla}})</fragment>
            </template>
            <template v-slot:item.groupArea.code="{ item }">
                {{ item.groupArea.code }} - {{ item.groupArea.name }}
            </template>
            <template v-slot:item.letArea.code="{ item }">
                {{ item.letArea.code }} - {{ item.letArea.name }}
            </template>

        </smart-table>
    </div>
</template>
<script>
import areaDataService from './areaData';
import SuccessIcon from '@/components/common/SuccessIcon';

import mathUtil from '@/../common/utils/math';

const headers = [

    {
        text: 'Group',
        align: 'right',
        value: 'groupArea.code',
    },
    {
        text: 'Group GLA',
        align: 'center',
        value: 'groupArea.gla',
    },
    {
        text: 'Let GLA',
        align: 'center',
        value: 'letArea.gla',
    },
    {
        text: 'Group NLA',
        align: 'center',
        value: 'groupArea.nla',
    },
    {
        text: 'Let NLA',
        align: 'center',
        value: 'letArea.nla',
    },
    {
        text: 'Lettable',
        align: 'left',
        value: 'letArea.code',
    },
];


export default {
    components: { SuccessIcon },
    data: () => ({
        headers,
        leases: areaDataService.leases,
        bills: areaDataService.bills,
        billProfiles: areaDataService.billProfiles,
        areas: null,
        formModel: {
            totalAmount: 0,

            invoiceReference: '',
        }
    }),
    methods: {
        getExpandedAreas() {
            return areaDataService.getExpandedAreas();
        },
        getNlaPerc(item) {
            try {
                return mathUtil.round(item.letArea.nla / item.groupArea.nla * 100)
            } catch (e) {
                return 0;
            }
        },
    },
    mounted() {
        console.log('areas', this.areas);
        const expandedAreas = this.getExpandedAreas();
        console.log('expanded areas', expandedAreas);

        const groupAreas = expandedAreas
            .filter((oneArea) => oneArea.groupArea.code !== oneArea.letArea.code)
            .map((oneArea) => ({
                ...oneArea,
                isMatchingGla: oneArea.totalCalcedGla === oneArea.groupArea.gla,
                isMatchingNla: oneArea.totalCalcedNla === oneArea.groupArea.nla,
            }));

        const nodeAreas = expandedAreas
            .filter((oneArea) => oneArea.groupArea.code === oneArea.letArea.code)
            .map((oneArea) => ({
                ...oneArea,
                isMatchingGla: oneArea.totalCalcedGla === oneArea.groupArea.gla,
                isMatchingNla: oneArea.totalCalcedNla === oneArea.groupArea.nla,
            }));

        this.areas = [...groupAreas, ...nodeAreas];
    },
};
</script>
